@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:300,400,700');

* {
  font-size: 16px;
  line-height: 1.5;
}

body {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  height: 100%;
}

h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin: 0 0 40px 0;
}

h2 {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  color: #0095ad;
}

button {
  font-size: 1.3rem;
  border-radius: 5px;
  padding: 5px 15px;
  background: #0095ad;
  color: white;
  border: none;
  margin: 5px 0px;
}

.container-fluid {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 !important;
  .row {
    margin: 0;
  }
}

.logo {
  text-align: center;
  img {
    max-height: 15vh;
  }
  flex: 1 0 auto;
}

.footer {
  background: url('assets/images/ikkijkverder_footer.png');
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  margin: 0;
  height: 30vh;
}

.content-wrapper {
  flex: 1 0 auto;
  background: url('assets/images/background.jpg');
  background-size: cover;
}

.content-container {
  padding-top: 50px;
  background-color: #fff;
  min-height: 100%;
  .discription {
    font-size: 0.75rem;
  }
}


.title {
  text-align:  center;
}

.payoff {
  flex: 1 0 auto;
  margin-top: 50px;
  text-align: center;
  .payoff-text {
    font-size: 1.9rem;
    margin: 0;
    padding: 0;
    color: #fff;
  }
  .payoff-text__big {
    text-transform: uppercase;
    font-size: 2.3rem;
    color: #0095ad;
  }
}



// FORM

.form-check-container {
  margin-top: 20px;
}

.form-check-label {
  color: #0095ad;
  line-height: 1.2;
}

.form-group {
  padding: 5px 0px;
  label {
    padding: 0px;
    color: #0095ad;
  }
  span {
    padding: 2px 15px;
    width: 100%;
    color: red;
  }
}

.form-send {
  margin-top: 20px;
  text-align: center;
}

.form-response {
  font-size: 1.5rem;
  text-align: center;
  color: #0095ad;
}


@media screen and (max-width: 960px) {
  .footer {
    background: url('assets/images/footer.png');
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
    margin: 0;
    height: 30vh;
  }
  .content-container {
    background: none;
  }
  .logo {
    padding-left:30px;
    text-align: left;
    order: 1;
  }
  .content-container {
    order: 2;
  }
  .payoff {
    order: 1;
  }
  .footer {
    order: 5;
  }
}

@media screen and (max-width: 576px) {
  .logo {
    text-align: center;
  }
  .footer {
    height: 15vh;
  }
  .payoff {
    display: none;
  }
}